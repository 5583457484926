<template>
  <div v-if="loaded" class="sl_standard_layout">
    <div class="main-container">
      <h2 class="sl__h2 container-header">
        Congratulations—you did it!
      </h2>

      <div class="container-body">
        <p class="sl__p">
          You’ve reached the end of our business guide.
        </p>
        <p class="sl__p">
          We’re getting ready to launch more business services and resources—check back soon!
        </p>

        <br>

        <h3 class="sl__h3">
          Next Steps
        </h3>

        <br>

        <div v-if="isSlide" class="cards-container">
          <standard-horizontal-card
            title="Start or Add Another Business"
            description="Start a business or add your existing one-we'll help you maintain and grow it."
            :width="50"
            :image="{ png: cardImages.convertCompany.png, webp: cardImages.convertCompany.webp }"
            @selected="navigateToRoot"
          />
          <standard-horizontal-card
            title="Explore All of Our Products and Services"
            description="Address, phone, website, and many other services to support your business at every stage."
            :width="50"
            :image="{ png: cardImages.hireUs.png, webp: cardImages.hireUs.webp }"
            @selected="navigateToHireUs"
          />
        </div>
      </div>
    </div>
  </div>
  <ct-centered-spinner v-else />
</template>

<script>
import ConvertCompanyPng from '@images/illustrations/products/agreement.png'
import ConvertCompanyWebp  from '@images/illustrations/products/agreement.webp'
import HireUsPng from '@images/illustrations/products/default.png'
import HireUsWebp  from '@images/illustrations/products/default.webp'
import { mapGetters } from 'vuex'

export default {
  name: 'StandardEndSlide',
  components: {
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    StandardHorizontalCard: () => import('@/components/StagelineV2/cards/StandardHorizontalCard.vue'),
  },
  props: {
    isSlide: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      loaded: false,
      cardImages: {
        convertCompany: {
          png: ConvertCompanyPng,
          webp: ConvertCompanyWebp,
        },
        hireUs: {
          png: HireUsPng,
          webp: HireUsWebp,
        },
      },
    }
  },
  computed: {
    ...mapGetters('stageline', ['company']),
  },
  mounted() {
    this.loaded = true
  },
  methods: {
    async navigateToRoot() {
      this.loaded = false

      await this.$router.push({
        name: 'root',
      }).catch(()=>{})
    },
    async navigateToHireUs() {
      this.loaded = false

      await this.$router.push({
        name: 'choose-category',
        params: { companyId: this.company.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.cards-container {
  width: 100%;
  display: inline-flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 1.5em;
}
</style>
