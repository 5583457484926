var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "sl_standard_layout" }, [
        _c("div", { staticClass: "main-container" }, [
          _c("h2", { staticClass: "sl__h2 container-header" }, [
            _vm._v("\n      Congratulations—you did it!\n    "),
          ]),
          _c("div", { staticClass: "container-body" }, [
            _c("p", { staticClass: "sl__p" }, [
              _vm._v(
                "\n        You’ve reached the end of our business guide.\n      "
              ),
            ]),
            _c("p", { staticClass: "sl__p" }, [
              _vm._v(
                "\n        We’re getting ready to launch more business services and resources—check back soon!\n      "
              ),
            ]),
            _c("br"),
            _c("h3", { staticClass: "sl__h3" }, [
              _vm._v("\n        Next Steps\n      "),
            ]),
            _c("br"),
            _vm.isSlide
              ? _c(
                  "div",
                  { staticClass: "cards-container" },
                  [
                    _c("standard-horizontal-card", {
                      attrs: {
                        title: "Start or Add Another Business",
                        description:
                          "Start a business or add your existing one-we'll help you maintain and grow it.",
                        width: 50,
                        image: {
                          png: _vm.cardImages.convertCompany.png,
                          webp: _vm.cardImages.convertCompany.webp,
                        },
                      },
                      on: { selected: _vm.navigateToRoot },
                    }),
                    _c("standard-horizontal-card", {
                      attrs: {
                        title: "Explore All of Our Products and Services",
                        description:
                          "Address, phone, website, and many other services to support your business at every stage.",
                        width: 50,
                        image: {
                          png: _vm.cardImages.hireUs.png,
                          webp: _vm.cardImages.hireUs.webp,
                        },
                      },
                      on: { selected: _vm.navigateToHireUs },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ])
    : _c("ct-centered-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }